import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import Layout from '../components/layout'
// import colorfulBalls from '../assets/illustrations/animated/colorful_particles.svg'
import starFish from '../assets/baby-pack/starfish.svg'
import medal from '../assets/baby-pack/medal.svg'
import science from '../assets/baby-pack/science.svg'
// import photoTwo from '../images/02.jpg'
// import photoEight from '../images/08.jpg'
import beachWheel from '../assets/baby-pack/beach_wheel.svg'
import targetBlank from '../assets/icons/target_blank.svg'

const myApproach = () => {
  const image = useStaticQuery(graphql`
    query {
      photoTwo: file(relativePath: { eq: "02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      photoEight: file(relativePath: { eq: "08.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <section className="bg-salmony-0">
        <section className="relative h-auto">
          <div
            id="questions"
            className="relative flex flex-col justify-center text-lg text-center lg:text-2xl lg:p-8 xl:max-w-5xl xl:mx-auto"
          >
            <h3 className="mt-4 text-3xl font-bold text-purple-600 font-muli">
              Beaucoup des parents s’interrogent :
            </h3>
            <span className="m-2 text-xl italic border-l-2 border-pink-300 font-header quotation text-custom-purple">
              Pourquoi mon enfant a du mal à s&apos;endormir ?
            </span>
            <span className="m-2 text-xl italic border-l-2 border-pink-300 font-header quotation text-custom-purple">
              Pourquoi se réveille-t-il fréquemment pendant la nuit ?
            </span>
            <span className="m-2 text-xl italic border-l-2 border-pink-300 font-header quotation text-custom-purple">
              Pourquoi n’arrive-t-il pas à faire des siestes assez longues et reposantes ?
            </span>
          </div>
        </section>

        <div id="official-info" className="mt-4">
          <p className="relative px-4 py-2 text-lg text-justify text-white font-muli md:w-5/6 xl:max-w-5xl xl:mx-auto md:mx-auto lg:text-xl text-custom-purple">
            <span className="line-separator line-separator-darker tooltip">
              Selon les statistiques officielles
              <span className="tooltiptext">
                Étude commandée par “Réseau Morphée, plus{' '}
                <a
                  className="inline-block underline"
                  href="http://sommeilenfant.reseau-morphee.fr/bebe/pathologies-syndromes/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  d&apos;informations ici <img className="inline-block h-4 text-white" src={targetBlank} />
                </a>
              </span>
            </span>
            : au moins 30% des enfants rencontrent des difficultés de sommeil et ont besoin d’aide pour le
            retrouver. Soyez rassurés, si votre enfant est en bonne santé, ces difficultés sont probablement
            liées à certains comportements, tels que: des mauvaises habitudes, des associations négatives ou
            des interventions précoces des parents, qui empêchent le bébé d’apprendre à s’endormir tout seul.
          </p>

          <p className="relative px-4 py-2 text-lg text-justify text-white font-muli md:w-5/6 xl:max-w-5xl xl:mx-auto md:mx-auto lg:text-xl text-custom-purple">
            Lorsque les parents me font confiance dans leur démarche d’aider leur enfant(s) à retrouver le
            chemin vers le merveilleux pays du sommeil, afin d’y arriver, on s’en tiendra aux piliers
            suivants:
          </p>
        </div>

        <div
          id="my-skillset"
          className="relative mx-4 mt-20 text-xl md:w-5/6 md:mx-auto xl:max-w-5xl xl:mx-auto"
        >
          <section className="flex items-center font-muli">
            <article style={{ flex: '3' }}>
              <h3 className="mb-2 text-3xl underlined">
                <img
                  src={starFish}
                  alt="étoile de mer"
                  className="absolute h-16 transform rotate-45"
                  style={{ top: '-1rem', left: '-1.25rem', zIndex: '-2' }}
                />
                Une approche personnalisée
              </h3>

              <p>
                Chaque enfant est unique ! C’est pourquoi mon approche est 100% personnalisée : elle sera
                adaptée à chaque famille et à chaque enfant. Je vais analyser individuellement chaque
                situation : l’organisation de vos journées, les habitudes de sommeil de votre enfant et votre
                comportement.
                <br />
                <br />
                Chaque détail est important. Pour formuler des recommandations personnalisées, plusieurs
                facteurs seront pris en compte : la personnalité de votre enfant (son caractère et
                tempérament), mais aussi votre nature parentale et vos valeurs familiales.
              </p>

              <h3 className="mt-6 mb-2 text-3xl underlined font-muli">
                <img
                  src={science}
                  alt="méthode scientifique"
                  className="absolute h-16 transform rotate-45"
                  style={{ top: '-1rem', left: '-1rem', zIndex: '-2' }}
                />
                Des méthodes scientifiques
              </h3>
              <p>
                Toutes les méthodes que j’utilise ont été scientifiquement approuvées par des chercheurs, des
                pédiatres et des spécialistes du sommeil du monde entier.
              </p>
            </article>
            <aside className="hidden lg:block" style={{ flex: '2' }}>
              <Img
                fluid={image.photoTwo.childImageSharp.fluid}
                alt="photo de bébé"
                className="mx-4 rounded-2xl"
              />
              <p className="text-sm text-center text-gray-700">
                Photo Mélanie Cassandre Photograph Artisan d&apos;art
              </p>
            </aside>
          </section>

          <section className="flex items-center lg:mt-8">
            <aside className="hidden lg:block" style={{ flex: '2' }}>
              <Img
                fluid={image.photoEight.childImageSharp.fluid}
                alt="photo de bébé"
                className="rounded-2xl"
              />
              <p className="text-sm text-center text-gray-700">
                Photo Mélanie Cassandre Photograph Artisan d&apos;art
              </p>
            </aside>
            <article className="lg:ml-16 font-muli" style={{ flex: '3' }}>
              <h3 className="mt-8 mb-2 text-3xl underlined font-muli">
                <img
                  src={beachWheel}
                  alt="roue de sauvetage"
                  className="absolute h-12 transform rotate-45"
                  style={{ top: '-0.25rem', left: '-1rem', zIndex: '-2' }}
                />
                Accompagnement et soutien
              </h3>
              <p>
                Je vais vous apporter des solutions, vous accompagner, vous encourager et vous donner des
                recommandations utiles tout au long de notre travail commun.
              </p>

              <h3 className="mt-6 mb-2 text-3xl underlined font-muli">
                <img
                  src={medal}
                  alt="résultat concret"
                  className="absolute h-12"
                  style={{ left: '-1.25rem', zIndex: '-2' }}
                />
                Un résultat assuré
              </h3>
              <p>
                C’est important de savoir que le résultat désiré est le fruit d’une collaboration entre moi,
                votre consultante, et vous, les parents. Mes recommandations et votre implication sont les
                deux facteurs qui garantiront des nuits tranquilles à toute la famille !
              </p>
            </article>
          </section>
          <br />
          <h3 className="my-6 text-2xl text-center font-muli">
            Dans cette vidéo, je vous présente les étapes principales de la &quot;Méthode des 5 pas&quot; que
            nous allons traverser ensemble vers le pays du sommeil. 😴
          </h3>
          <iframe
            className="w-full max-w-3xl mx-auto lg:w-9/12 h-80"
            src="https://www.youtube.com/embed/U6du8x-o5KU"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>
    </Layout>
  )
}

export default myApproach
